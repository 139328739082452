import { useState } from 'react';
import { Input, Collapse, Checkbox, Typography, Alert, Select, Button, QRCode, notification } from 'antd';
import { ClearOutlined, EyeOutlined, EyeInvisibleOutlined, ExclamationCircleOutlined, ArrowsAltOutlined, QrcodeOutlined, VerticalAlignBottomOutlined, SaveOutlined } from '@ant-design/icons';

import { Vault, VaultEntry } from './utils/vault';
import WifiQRModal from './WifiQRModal';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

const { Panel } = Collapse;
const { Text } = Typography;

interface WifiQRGeneratorProps {
  className?: string;
  vault: Vault;
  setVault: (vault: Vault) => void;
}

const WifiQRGenerator: React.FC<WifiQRGeneratorProps> = ({ className, vault, setVault }) => {
  const authTypeWPALabel = 'WPA/WPA2/WPA3';
  const authTypeWPAValue = 'WPA';

  const authTypeWEPLabel = 'WEP';
  const authTypeWEPValue = 'WEP';

  const [ssid, setSsid] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [authType, setAuthType] = useState<string>(authTypeWPAValue);
  const [hidden, setHidden] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [showQR, setShowQR] = useState<boolean>(false);
  const [savedQR, setSavedQR] = useState<boolean>(false);
  const [presentQR, setPresentQR] = useState<boolean>(false);

  const buildEntry = (): VaultEntry => {
    return new VaultEntry(ssid, authType, password, hidden);
  };
  const uri = buildEntry().toURI();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const isEmptyOrWhitespace = (value: string) => {
    return !value || value.trim().length === 0;
  }

  const showErrorNotification = (message: string, description: string) => {
    notification.open({
      message: <span style={{ color: '#ffffff' }}>{message}</span>,
      description: <span style={{ color: '#ffffff' }}>{description}</span>,
      icon: <ExclamationCircleOutlined style={{ color: '#ffffff' }} />,
      closeIcon: <></>,
      style: {
        border: '1px solid #ff4d4f',
        backgroundColor: '#2a1215',
        borderRadius: '8px',
      },
      placement: 'top',
      duration: 3,
    });
  };

  const generateQRCode = () => {
    if (isEmptyOrWhitespace(ssid) || isEmptyOrWhitespace(authType) || isEmptyOrWhitespace(password)) {
      showErrorNotification('Error', 'Missing required fields!');
      return;
    }

    setShowQR(true);
  };

  const saveQRCode = () => {
    if (!showQR || savedQR) {
      return;
    }

    const newEntry = buildEntry();
    const newVault = { ...vault, [name]: newEntry };

    setVault(newVault);

    setSavedQR(true);
  };

  const clearState = () => {
    setSavedQR(false);
    setShowQR(false);
  };

  const clearAll = () => {
    clearState();
    setSsid('');
    setPassword('');
    setAuthType(authTypeWPAValue);
    setHidden(false);
    setName('');
  };

  const onSetHidden = (e: CheckboxChangeEvent) => {
    clearState();
    setHidden(e.target.checked);
  };

  const onSetAuthType = (value: string) => {
    clearState();
    setAuthType(value);
  };

  const onSetPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearState();
    setPassword(e.target.value);
  };

  const onSetSsid = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearState();
    setSsid(e.target.value);
  };

  const onSetName = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Do not clear state here
    setName(e.target.value);
  };

  const download = (url: string, fileName: string) => {
    const a = document.createElement('a');
    a.download = fileName;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const downloadQRCode = () => {
    const canvas = document.getElementById('qrcode')?.querySelector<HTMLCanvasElement>('canvas');
    if (canvas) {
      const url = canvas.toDataURL();
      download(url, `wifi_qr_${ssid}.png`);
    }
  };

  const defaultActiveKey = () => {
    // If has vault, do not show this component by default
    return Object.keys(vault).length === 0 ? ['1'] : [];
  };

  const authTypes = [
    {
      value: authTypeWPAValue,
      label: authTypeWPALabel,
    },
    {
      value: authTypeWEPValue,
      label: authTypeWEPLabel,
    },
  ];

  return (
    <div className={className}>
      <WifiQRModal
        value={uri}
        opened={presentQR}
        close={() => setPresentQR(false)}
      />
      <Collapse defaultActiveKey={defaultActiveKey()}>
        <Panel header={<Text strong>Generate WiFi QR codes</Text>} key="1">
          <div className="space-y-4">
            <Input
              type="text"
              placeholder="WiFi Network Name (SSID)"
              value={ssid}
              onChange={onSetSsid}
            />
            <Select
              defaultValue={authTypeWPALabel}
              options={authTypes}
              className="w-full left-justify"
              onChange={onSetAuthType}
            />
            <div className="relative">
              <Input
                type={showPassword ? "text" : "password"}
                placeholder="WiFi Password"
                value={password}
                onChange={onSetPassword}
              />
              <Button
                type="text"
                size="small"
                icon={showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                onClick={togglePasswordVisibility}
                style={{
                  position: 'absolute',
                  right: '5px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  border: 'none',
                  background: 'transparent',
                }}
              />
            </div>
            <Checkbox onChange={onSetHidden}>Hidden</Checkbox>
            <Button
              onClick={generateQRCode}
              icon={<QrcodeOutlined/>}
              type="primary"
              className="w-full"
            >
              Generate QR Code
            </Button>
            {showQR && (
              <div id="qrcode" className="flex justify-center">
                <QRCode
                  errorLevel="Q"
                  value={uri}
                />
              </div>
            )}
            {showQR && (
              <div className="flex justify-center space-x-2">
                <Button
                  onClick={downloadQRCode}
                  icon={<VerticalAlignBottomOutlined />}
                  size="small"
                >
                  Download
                </Button>
                <Button
                  onClick={() => setPresentQR(true)}
                  icon={<ArrowsAltOutlined />}
                  size="small"
                >
                  Preview
                </Button>
                <Button
                  onClick={clearAll}
                  icon={<ClearOutlined />}
                  size="small"
                >
                  Clear
                </Button>
              </div>
            )}
            {showQR && !savedQR && (
              <div className="flex justify-center space-x-2">
                <Input
                  type="text"
                  size="small"
                  placeholder="Name"
                  style={{ width: '200px' }}
                  value={name}
                  onChange={onSetName}
                />
                <Button
                  onClick={saveQRCode}
                  icon={<SaveOutlined />}
                  size="small"
                  disabled={isEmptyOrWhitespace(name)}
                >
                  Save
                </Button>
              </div>
            )}
            {showQR && savedQR && (
              <Alert
                message="Saved!"
                type="success"
                showIcon
              />
            )}
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default WifiQRGenerator;