export enum ViewMode {
    List = 'list',
    Grid = 'grid',
}

export const toViewMode = (value: string): ViewMode | undefined => {
    const values = Object.values(ViewMode);
    if (values.includes(value as ViewMode)) {
      return value as ViewMode;
    }
    return undefined;
}

const viewModeKey = "view";

export const loadViewMode = (): ViewMode => {
    const def: ViewMode = ViewMode.List;

    const viewModeStr = localStorage.getItem(viewModeKey);
    if (viewModeStr === null) {
        storeViewMode(def);
        return def;
    }

    const viewMode = toViewMode(viewModeStr);
    if (viewMode === undefined) {
        storeViewMode(def);
        return def;
    }

    return viewMode;
};

export const storeViewMode = (viewMode: ViewMode): void => {
    localStorage.setItem(viewModeKey, viewMode);
};