import React, { useState, useEffect } from 'react';
import { QRCode, Button, Popover, Popconfirm, Collapse, Input, Radio, List, Tooltip, Card, Row, Col, Typography, Empty, RadioChangeEvent } from 'antd';
import { QrcodeOutlined, ArrowsAltOutlined, DeleteOutlined, MenuOutlined, TableOutlined } from '@ant-design/icons';

import { Vault, VaultEntry } from './utils/vault';
import { loadViewMode, storeViewMode, toViewMode, ViewMode } from './utils/view';

import WifiQRModal from './WifiQRModal';

const { Panel } = Collapse;
const { Search } = Input;
const { Text } = Typography;

interface WifiQRVaultProps {
  className?: string;
  vault: Vault;
  setVault: (vault: Vault) => void;
}

const WifiQRVault: React.FC<WifiQRVaultProps> = ({ className, vault, setVault }) => {
  const [viewMode, setViewMode] = useState<ViewMode>(loadViewMode());
  const [screenSize, setScreenSize] = useState<number>(window.innerWidth);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedURI, setSelectedURI] = useState<string>('');

  const filteredEntries: [string, VaultEntry][] = Object.entries(vault).filter(
    ([name]) => name.toLowerCase().includes(searchTerm.toLowerCase())
  ) as [string, VaultEntry][];

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const deleteItem = (name: string) => {
    const newVault = { ...vault };
    delete newVault[name];
    setVault(newVault);
  };

  const onSetViewMode = (e: RadioChangeEvent) => {
    const viewMode = toViewMode(e.target.value);
    if (viewMode === undefined) {
      console.log('Invalid view mode', e.target.value);
      return;
    }

    setViewMode(viewMode);
    storeViewMode(viewMode);
  };

  const popoverContent = (entry: VaultEntry) => {
    const uri = entry.toURI();
    return (
      <QRCode
        bordered={false}
        errorLevel="Q"
        value={uri}
      />
    )
  };

  const gridColSpan = screenSize < 640 ? 12 : 6;
  const renderGridView = () => (
    filteredEntries.length > 0 ? (
      <Row gutter={[16, 16]}>
        {filteredEntries.map(([name, entry]) => (
          <Col key={name} className="gutter-row" span={gridColSpan}>
            <Tooltip title={name}>
              <Card
                hoverable
                actions={[
                    <Button
                      size="small"
                      icon={<ArrowsAltOutlined />}
                      onClick={() => setSelectedURI(entry.toURI())} />,
                    <Popconfirm
                    title="Are you sure you want to delete this item?"
                    onConfirm={(e) => { e?.stopPropagation(); deleteItem(name); }}
                    onCancel={(e) => e?.stopPropagation()}
                    okText="Yes"
                    cancelText="No"
                    >
                      <Button
                        size="small"
                        icon={<DeleteOutlined />}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </Popconfirm>,
                ]}
              >
                <Popover content={popoverContent(entry)} trigger="click">
                  <div className="cursor-pointer transition-transform duration-200 hover:scale-105">
                    <QrcodeOutlined />
                    <Card.Meta title={<span className="text-sm">{name}</span>} />
                    <Card.Meta title={<span className="text-xs italic">[ssid: {entry.ssid}]</span>} />
                  </div>
                </Popover>
              </Card>
            </Tooltip>
          </Col>
        ))}
      </Row>
    ) : (
      <Empty description="No entries found" />
    )
  );

  const renderListView = () => (
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={filteredEntries}
      renderItem={([name, entry]) => (
        <List.Item
          key={name}
          className="flex items-center justify-between w-full"
        >
          <Popover content={popoverContent(entry)} trigger="click">
            <div className="flex flex-1 items-center space-x-4 cursor-pointer transition-transform duration-200 hover:scale-105 overflow-hidden">
              <QrcodeOutlined className="flex-shrink-0" />
              <div className="flex-shrink-0 whitespace-nowrap">{name}</div>
              <div className="text-xs italic truncate">
                [ssid: {entry.ssid}]
              </div>
            </div>
          </Popover>
          <div className="flex justify-end space-x-2 flex-shrink-0 ml-4">
            <Button
              icon={<ArrowsAltOutlined />}
              size="small"
              onClick={() => setSelectedURI(entry.toURI())}
            />
            <Popconfirm
              title="Are you sure you want to delete this item?"
              onConfirm={() => deleteItem(name)}
              okText="Yes"
              cancelText="No"
            >
              <Button icon={<DeleteOutlined />} size="small" />
            </Popconfirm>
          </div>
        </List.Item>
      )}
      locale={{ emptyText: <Empty description="No entries found" /> }}
    />
  );

  const defaultActiveKey = () => {
    // If there is a vault, show this component by default
    return Object.keys(vault).length > 0 ? ['1'] : [];
  }

  return (
    <div className={className}>
      <WifiQRModal
        value={selectedURI}
        opened={selectedURI !== ''}
        close={() => setSelectedURI('')}
      />
      <Collapse defaultActiveKey={defaultActiveKey()}>
        <Panel header={<Text strong>Stored WiFi QR codes</Text>} key="1">
          <div className="flex mb-[16px] space-x-4">
            <Search
              placeholder="Search WiFi SSID"
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ flex: 1 }}
            />
            <Radio.Group
              onChange={onSetViewMode}
              value={viewMode}
            >
              <Radio.Button value="list"><MenuOutlined /></Radio.Button>
              <Radio.Button value="grid"><TableOutlined /></Radio.Button>
            </Radio.Group>
          </div>
          <div style={{
            maxHeight: '300px',
            overflowY: 'auto',
            overflowX: 'hidden',
            scrollbarWidth: 'thin',
            scrollbarColor: '#1890ff transparent'
          }}>
            {viewMode === 'grid' ? renderGridView() : renderListView()}
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default WifiQRVault;
