import { Modal, Typography, Button, QRCode } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const { Title } = Typography;

interface WifiQRModalProps {
  value: string;
  opened: boolean;
  close: () => void;
}

const WifiQRModal: React.FC<WifiQRModalProps> = ({ value, opened, close }) => {
  if (!opened) {
    return null;
  }

  return (
    <Modal
      centered
      open={opened}
      onCancel={close}
      footer={null}
      closable={false}
      styles={{
        mask: {
          backdropFilter: 'blur(4px)',
        },
        body: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }
      }}
    >
      <div className="flex flex-col items-center space-y-5">
        <Title style={{ margin: 5 }}>Connect to the WiFi</Title>
        <Title style={{ margin: 0 }} level={5}>Simply scan this QR code using your phone's camera</Title>
        <QRCode bordered={false} value={value} size={300} />
        <Button
          icon={<CloseOutlined />}
          onClick={close}
          className="rounded-full"
        />
      </div>
    </Modal>
  );
};

export default WifiQRModal;