import React, { ReactNode } from 'react';
import { ConfigProvider, theme } from 'antd';
import type { ThemeConfig } from 'antd/es/config-provider/context';

const { darkAlgorithm } = theme;

const AppThemeConfig: ThemeConfig = {
  algorithm: darkAlgorithm,
  token: {
    colorPrimary: '#1890ff',
    colorLink: '#1890ff',
    colorSuccess: '#52c41a',
    colorWarning: '#faad14',
    colorError: '#f5222d',
    colorTextBase: 'rgba(255, 255, 255, 0.85)',
    colorBgBase: '#141414',
    borderRadius: 4,
    wireframe: false,
  },
  components: {
    Button: {
      colorPrimary: '#1890ff',
      algorithm: true, // Enable algorithm for button
    },
    Input: {
      colorBgContainer: '#2b2b2b',
      colorBorder: '#434343',
    },
    Table: {
      colorBgContainer: '#1f1f1f',
      colorFillAlter: '#262626',
    },
    Card: {
      colorBgContainer: '#1f1f1f',
    },
    Collapse: {
      colorBgContainer: '#1f1f1f',
    },
    Modal: {
      colorBgElevated: '#1f1f1f',
    },
    Select: {
      colorBgContainer: '#1f1f1f',
      colorBgElevated: '#1f1f1f',
    },
    DatePicker: {
      colorBgContainer: '#1f1f1f',
      colorBgElevated: '#1f1f1f',
    },
  },
};

interface ThemeProviderProps {
  children: ReactNode;
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => (
  <ConfigProvider theme={AppThemeConfig}>
    {children}
  </ConfigProvider>
);

export default ThemeProvider;