export class VaultEntry {
    ssid: string;
    password: string;
    authType: string;
    hidden: boolean;

    constructor(ssid: string, authType: string, password: string, hidden: boolean) {
        this.ssid = ssid;
        this.authType = authType;
        this.password = password;
        this.hidden = hidden;
    }

    toURI(): string {
        return `WIFI:S:${this.ssid};T:${this.authType};P:${this.password};H:${this.hidden};`;
    }
}

export type Vault = {
    [name: string]: VaultEntry;
};

const vaultKey = "vault";

const getVault = (): string | null => {
    return localStorage.getItem(vaultKey);
}

export const hasVault = (): boolean => {
    const vaultStr = getVault();
    return vaultStr !== null;
}

export const loadVault = (): Vault => {
    const vaultStr = getVault();
    if (vaultStr === null) {
        return {};
    }

    const parsedVault = JSON.parse(vaultStr);
    const vault: Vault = {};
    for (const key in parsedVault) {
        if (parsedVault.hasOwnProperty(key)) {
            const entry = parsedVault[key];
            vault[key] = new VaultEntry(entry.ssid,
                entry.authType, entry.password, entry.hidden);
        }
    }
    return vault;
};

export const storeVault = (vault: Vault): void => {
    const vaultStr = JSON.stringify(vault);
    localStorage.setItem(vaultKey, vaultStr);
};