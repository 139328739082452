import React, { useState } from 'react';

import WifiQRVault from './WifiQRVault';
import WifiQRGenerator from './WifiQRGenerator';

import { loadVault, storeVault, Vault } from './utils/vault';

interface WifiQRPageProps {
}

const WifiQRPage: React.FC<WifiQRPageProps> = () => {
  const [vault, setVault] = useState<Vault>(loadVault());

  const updateVault = (vault: Vault) => {
    storeVault(vault);
    setVault(vault);
  }

  return (
    <div className="w-[320px] sm:w-[640px] mx-auto p-4 space-y-4" >
      <WifiQRVault
        className="w-full"
        vault={vault}
        setVault={updateVault} />
      <WifiQRGenerator
        className="w-full"
        vault={vault}
        setVault={updateVault} />
    </div>
  );
};

export default WifiQRPage;