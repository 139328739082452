import React from 'react';
import { Typography, theme } from 'antd';
import logo from './logo.svg';

const { useToken } = theme;
const { Title } = Typography;

const Logo: React.FC = () => {
    const { token } = useToken();

    return (
        <div className="flex justify-center items-center flex-col">
            <img src={logo} alt="Logo" className="w-[60px] h-[60px]" />
            <Title level={1} style={{ color: token.colorPrimary }}>Pass my WiFi</Title>
        </div>
    );
};

export default Logo;