import './App.css';
import React from 'react';
import { Space } from 'antd';
import ThemeProvider from './Theme';
import WifiQRPage from './WifiQRPage';
import Logo from './Logo';

const App: React.FC = () => (
  <ThemeProvider>
    <div className="App">
      <header className="App-header">
        <Space direction="vertical">
          <Logo />
          <WifiQRPage />
        </Space>
      </header>
    </div>
  </ThemeProvider>
);

export default App;
